import React from 'react';
import PropTypes from 'prop-types';

import InfoSlide from './info-slide';
import Section from '../section';

function SiteShoppingInfoDesktop({
  componentId,
  type,
  elements,
}) {
  return (
    <Section type={type}>
      <div className="container">
        {elements.map(element => (
          <InfoSlide
            key={`${componentId}-${element.title_label}`}
            {...element}
          />
        ))}
      </div>
    </Section>
  );
}

SiteShoppingInfoDesktop.propTypes = {
  componentId: PropTypes.string.isRequired,
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      title_label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  type: PropTypes.string.isRequired,
};

export default SiteShoppingInfoDesktop;
